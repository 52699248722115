const circleSubstation = {
    radius: 10,
    fillColor: '#00497a',
    color: '#00497a',
    weight: 2,
    opacity: 1,
    fillOpacity: 0.2
};

export { circleSubstation };
