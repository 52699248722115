import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Customer } from '../../models/customer';

export enum OverlayComponentName {
    DetailsCustomer = 'DETAILS-CUSTOMER',
    DetailsOutage = 'DETAILS-OUTAGE',
    DetailsIncident = 'DETAILS-INCIDENT',
    MapLayerSettings = 'MAP-LAYER-SETTINGS'
}

interface IOverlay {
    component: OverlayComponentName;
    params?: IParams;
}

interface IParams {
    incidentId?: number;
    outageId?: number;
    customer?: Customer;
}

@Injectable()
export class EdisOverlayService {
    public showSubject = new Subject<IOverlay>();
    public showObservable = this.showSubject.asObservable();

    public hideSubject = new Subject<IOverlay>();
    public hideObservable = this.hideSubject.asObservable();

    public shakeSubject = new Subject<IOverlay>();
    public shakeObservable = this.shakeSubject.asObservable();

    public show(data: IOverlay): void {
        this.showSubject.next(data);
    }

    public hide(): void {
        this.hideSubject.next();
    }

    public shake(): void {
        this.shakeSubject.next();
    }
}
