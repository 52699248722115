import { MapComponent } from '@yaga/leaflet-ng2';
import { EdisLiveDataService, ILiveData } from '../../../services/live-data/live-data.service';
import { Outage } from '../../../models/outage';
import { featureGroup, FeatureGroup, icon, Icon, marker, LatLng, geoJSON, GeoJSON } from 'leaflet';
import { VoltageType } from '../../../enums/voltage-type.enum';
import { Status } from '../../../enums/status.enum';
import { Router } from '@angular/router';
import { EdisOverlayService, OverlayComponentName } from '../../../services/overlay/overlay.service';
import { Unsubscribable } from 'rxjs';
import { Incident } from '../../../models/incident';

export class MapLayerIncidentsUnassigned {
    private liveDataSubscription: Unsubscribable;
    private featureGroup: FeatureGroup = featureGroup();
    private activeId: number;
    private incidents: Incident[] = [];
    private iconUrlIncidentTemplate =
        "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' version='1.1' width='50' height='63'%3E%3Crect width='100%25' height='100%25' x='0' y='0' fill='none' /%3E%3Cg%3E%3Cg%3E%3Cpath fill='%23BBB' d='M5,0 h40 c2.8,0 5,2.2 5,5 v40 c0,2.8 -2.2,5 -5,5 h-29.9 L0,63 v-58 C0,2.1999999999999993 2.1999999999999993,0 5,0 z' id='svg_5'/%3E%3C/g%3E%3Cpath fill='%2300497A' d='M17.2,13.2c-0.1,0-0.2,0.1-0.2,0.2v23c0,0.1,0.1,0.2,0.2,0.2h15.5c0.1,0,0.2-0.1,0.2-0.2v-23 c0-0.1-0.1-0.2-0.2-0.2H17.2z M17.2,11.8h15.5c1,0,1.8,0.8,1.8,1.8v23c0,1-0.8,1.8-1.8,1.8H17.2c-1,0-1.8-0.8-1.8-1.8v-23 C15.5,12.5,16.3,11.8,17.2,11.8z M25,16.8c0.4,0,0.8,0.3,0.8,0.8s-0.3,0.8-0.8,0.8l0,0h-5c-0.4,0-0.8-0.3-0.8-0.8s0.3-0.8,0.8-0.8 H25z M28.8,21.8c0.4,0,0.8,0.3,0.8,0.8s-0.3,0.8-0.8,0.8H20c-0.4,0-0.8-0.3-0.8-0.8s0.3-0.8,0.8-0.8H28.8z M30,26.8 c0.4,0,0.8,0.3,0.8,0.8s-0.3,0.8-0.8,0.8H20c-0.4,0-0.8-0.3-0.8-0.8s0.3-0.8,0.8-0.8H30z M26.2,31.8c0.4,0,0.8,0.3,0.8,0.8 s-0.3,0.8-0.8,0.8l0,0H20c-0.4,0-0.8-0.3-0.8-0.8s0.3-0.8,0.8-0.8H26.2z' id='svg_6'/%3E%3C/g%3E%3C/svg%3E";

    constructor(
        private map: MapComponent,
        private edisLiveDataService: EdisLiveDataService,
        private router: Router,
        private edisOverlayService: EdisOverlayService
    ) {
        this.edisOverlayService.hideObservable.subscribe(() => {
            this.activeId = undefined;
            this.router.navigate([], {
                queryParams: {}
            });
        });
    }

    public render(): Promise<Outage[]> {
        return new Promise((resolve) => {
            this.liveDataSubscription = this.edisLiveDataService.subscribeLiveData((data: ILiveData) => {
                this.incidents = data.unassignedIncidents;
                this.update();
                resolve();
            });
        });
    }

    public hide(): void {
        this.liveDataSubscription.unsubscribe();
        this.featureGroup.remove();
    }

    private update(): void {
        this.featureGroup.clearLayers();
        if (this.incidents) {
            this.incidents.forEach((incident) => {
                this.featureGroup.addLayer(
                    geoJSON(GeoJSON.asFeature(incident.address.geometry), {
                        pointToLayer: (feature, latlng) => {
                            return marker(latlng, {
                                icon: this.getIncidentIcon(
                                    incident.status,
                                    this.activeId === incident.id,
                                    this.activeId && this.activeId !== incident.id
                                )
                            }).on('click', (e: Event) => {
                                this.setIncidentActive(incident);
                            });
                        }
                    })
                );
            });

            this.featureGroup.addTo(this.map);
        }
    }

    private setIncidentActive(incident: Incident): void {
        this.activeId = incident.id;
        this.edisOverlayService.show({
            component: OverlayComponentName.DetailsIncident,
            params: { incidentId: incident.id }
        });
        this.router.navigate([], {
            queryParams: {
                incidentId: incident.id
            }
        });
        this.update();
        this.map.fitBounds(geoJSON(GeoJSON.asFeature(incident.address.geometry)).getBounds(), {
            maxZoom: this.map.getZoom()
        });
    }

    private getIncidentIcon(status: Status, isActive: boolean, isDeactivated: boolean): Icon {
        let color;
        let backgroundColor;

        if (isDeactivated) {
            backgroundColor = 'ffffff';
            color = '00497A';
        } else {
            color = 'ffffff';
            backgroundColor = '00497A';
        }

        let iconSize = [50, 63];
        let iconAnchor = [0, 63];

        if (isActive) {
            iconSize = [50 * 1.2, 63 * 1.2];
            iconAnchor = [0, 63 * 1.2];
        }

        return icon({
            iconUrl: this.iconUrlIncidentTemplate.replace('00497A', color).replace('BBB', backgroundColor),
            iconSize: [iconSize[0], iconSize[1]],
            iconAnchor: [iconAnchor[0], iconAnchor[1]]
        });
    }
}
