import { Component } from '@angular/core';
import { EdisMapService, MapLayerType } from '../../services/map/map.service';

@Component({
    selector: 'edis-map-layer-settings',
    templateUrl: './map-layer-settings.component.html',
    styleUrls: ['./map-layer-settings.component.scss']
})
export class EdisMapLayerSettingsComponent {
    public mapLayerType = MapLayerType;

    constructor(private edisMapService: EdisMapService) {}

    public updateLayer(isVisible: boolean, type: MapLayerType): void {
        this.edisMapService.updateLayerView(type, isVisible);
    }

    public isLayerVisible(type: MapLayerType): boolean {
        return this.edisMapService.isLayerVisible(type);
    }
}
