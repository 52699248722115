import { Component, OnDestroy, OnInit } from '@angular/core';
import { EdisNotificationService } from '../../../services/notification/notification.service';
import { Subscription } from 'rxjs';

@Component({
    selector: 'edis-notification',
    templateUrl: './notification.component.html',
    styleUrls: ['./notification.component.scss']
})
export class EdisNotificationComponent implements OnInit, OnDestroy {
    public isNotificationActive: boolean;
    public content: { headline: string; message: string } = { headline: '', message: '' };
    private subscription: Subscription;

    constructor(private notificationService: EdisNotificationService) {}

    public close(): void {
        this.isNotificationActive = false;
    }

    public ngOnInit(): void {
        this.subscription = this.notificationService.notificationListener.subscribe((content) => {
            this.showNotification(content);
        });
    }

    public ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    private showNotification(content: { headline: string; message: string }): void {
        this.content = content;
        this.isNotificationActive = true;
    }
}
