import { Component, Input } from '@angular/core';
import { Customer } from '../../models/customer';

@Component({
    selector: 'edis-details-customer',
    templateUrl: './details-customer.component.html',
    styleUrls: ['./details-customer.component.scss']
})
export class EdisDetailsCustomerComponent {
    @Input()
    customer: Customer;

    constructor() {}
}
