import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

import { qaBootstrap } from './qa';

if (environment.production) {
    enableProdMode();
}

const bootstrap = () => platformBrowserDynamic().bootstrapModule(AppModule);

if (environment.qa) {
    if (module['hot']) {
        qaBootstrap(module, bootstrap);
    } else {
        console.error('QA is not enabled for webpack-dev-server!');
        console.log('Are you using the --qa flag for ng serve?');
    }
} else {
    bootstrap()
        .then(() => {
            if ('serviceWorker' in navigator && environment.production) {
                navigator.serviceWorker.register('/ngsw-worker.js');
            }
        })
        .catch((err) => console.log(err));
}
