import { icon } from 'leaflet';

const iconSearchResult = icon({
    iconUrl: '/assets/images/pins/search.svg',
    iconSize: [88, 94],
    iconAnchor: [44, 80],
    popupAnchor: [0, -45]
});

export { iconSearchResult };
