import { Component } from '@angular/core';

@Component({
    selector: 'edis-main',
    templateUrl: './main.component.html',
    styleUrls: ['./main.component.scss']
})
export class EdisMainComponent {
    private currentDevice = 'keyboard';

    // we need this body class for proper focus styling of buttons, links etc.
    public updateDevice(device: 'mouse' | 'keyboard'): void {
        if (device !== this.currentDevice) {
            document.body.classList.remove('using-' + this.currentDevice);
            document.body.classList.add('using-' + device);
            this.currentDevice = device;
        }
    }
}
