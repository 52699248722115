import { Pipe, PipeTransform } from '@angular/core';
import { IncidentDetail } from '../../enums/incident-detail.enum';
import { IncidentNote } from '../../enums/incindent-note.enum';
import { Salutation } from '../../enums/salutation.enum';
import { Status } from '../../enums/status.enum';

const translations = {
    de: {
        details: {
            CAUSE_KNOWN: 'Störungsursache bekannt',
            IN_APPARTMENT: 'In der eigenen Wohnung',
            IN_HOUSE: 'Im Haus',
            IN_NEIGHBORHOOD: 'In den Nachbarhäusern',
            STREET_LIGHTNING: 'Straßenbeleuchtung brennt'
        },
        notes: {
            DEFECT_CONDUCTOR_ROPES: 'Defekte Leiterseile',
            FLASHES: 'Lichtblitze',
            IS_PLANNED: 'Geplant',
            STORM: 'Unwetter'
        },
        salutation: {
            FEMALE: 'Frau',
            MALE: 'Herr'
        },
        status: {
            OPEN: 'Offen',
            PROGRESS: 'In Bearbeitung',
            DONE: 'Behoben'
        }
    }
};

@Pipe({
    name: 'edisTranslate'
})
export class EdisTranslatePipe implements PipeTransform {
    transform(translate: { type: string; value: IncidentDetail | IncidentNote | Salutation | Status }): string {
        if (
            translations['de'] &&
            translations['de'][translate.type] &&
            translations['de'][translate.type][translate.value]
        ) {
            return translations['de'][translate.type][translate.value];
        } else {
            console.error('Translation not found for type "' + translate.type + '", value "' + translate.value + '"');
            return '';
        }
    }
}
