import { Component, Input } from '@angular/core';

@Component({
    selector: 'edis-user-dropdown',
    templateUrl: './user-dropdown.component.html',
    styleUrls: ['./user-dropdown.component.scss']
})
export class EdisUserDropdownComponent {
    @Input()
    public isUserDropdownOpen: boolean;

    constructor() {}

    public onClickOutside(): void {
        if (this.isUserDropdownOpen) {
            this.isUserDropdownOpen = false;
        }
    }

    public logOut(): void {}
}
