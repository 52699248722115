import { MapComponent } from '@yaga/leaflet-ng2';
import { EdisLiveDataService, ILiveData } from '../../../services/live-data/live-data.service';
import { Outage } from '../../../models/outage';
import { featureGroup, FeatureGroup, icon, Icon, marker, LatLng, geoJSON, GeoJSON } from 'leaflet';
import { VoltageType } from '../../../enums/voltage-type.enum';
import { Status } from '../../../enums/status.enum';
import { Router } from '@angular/router';
import { EdisOverlayService, OverlayComponentName } from '../../../services/overlay/overlay.service';
import { Unsubscribable } from 'rxjs';
import { Incident } from '../../../models/incident';

export class MapLayerOutages {
    private liveDataSubscription: Unsubscribable;
    private featureGroup: FeatureGroup = featureGroup();
    private activeId: number;
    private outages: Outage[] = [];
    private iconUrlOutageTemplate =
        "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='89' height='62' viewBox='0 0 89 62'%3E%3Cg transform='translate(30 30)'%3E%3Cpath fill='%23BBB' d='M-25-30h79c2.8 0 5 2.2 5 5v40c0 2.8-2.2 5-5 5h-68.9L-30 33v-58c0-2.8 2.2-5 5-5z'/%3E%3Cg%3E%3Cpath fill='%23CCC' d='M-17.7 4.9c-.4.6-.1 1.4.4 1.7.2.1.4.2.7.2H6.6c.7 0 1.3-.5 1.3-1.2 0-.2-.1-.5-.2-.7L-3.9-16.4c-.3-.6-1.1-.8-1.7-.5-.2.1-.4.3-.5.5L-17.7 4.9zm10.3-22c.7-1.3 2.4-1.8 3.7-1.1.5.3.8.6 1.1 1.1L9 4.2c.8 1.3.3 3-1 3.8-.4.2-.9.3-1.4.3h-23.3c-1.5 0-2.8-1.2-2.8-2.7 0-.5.1-1 .4-1.4.1 0 11.7-21.3 11.7-21.3zm1.6 7.1c0-.4.3-.8.8-.8s.8.3.8.8v7.5c0 .4-.3.8-.8.8s-.8-.3-.8-.8V-10zm1.6 12.5c0 .4-.3.8-.8.8s-.8-.3-.8-.8V1.2c0-.4.3-.8.8-.8s.8.3.8.8v1.3z'/%3E%3C/g%3E%3Cg enable-background='new'%3E%3Ctext transform='translate(20 -2)' fill='%23CCC' font-family='Arial' font-size='16'%3E LL %3C/text%3E%3C/g%3E%3C/g%3E%3C/svg%3E";

    private iconUrlIncidentTemplate =
        "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' version='1.1' width='50' height='63'%3E%3Crect width='100%25' height='100%25' x='0' y='0' fill='none' /%3E%3Cg%3E%3Cg%3E%3Cpath fill='%23BBB' d='M5,0 h40 c2.8,0 5,2.2 5,5 v40 c0,2.8 -2.2,5 -5,5 h-29.9 L0,63 v-58 C0,2.1999999999999993 2.1999999999999993,0 5,0 z' id='svg_5'/%3E%3C/g%3E%3Cpath fill='%2300497A' d='M17.2,13.2c-0.1,0-0.2,0.1-0.2,0.2v23c0,0.1,0.1,0.2,0.2,0.2h15.5c0.1,0,0.2-0.1,0.2-0.2v-23 c0-0.1-0.1-0.2-0.2-0.2H17.2z M17.2,11.8h15.5c1,0,1.8,0.8,1.8,1.8v23c0,1-0.8,1.8-1.8,1.8H17.2c-1,0-1.8-0.8-1.8-1.8v-23 C15.5,12.5,16.3,11.8,17.2,11.8z M25,16.8c0.4,0,0.8,0.3,0.8,0.8s-0.3,0.8-0.8,0.8l0,0h-5c-0.4,0-0.8-0.3-0.8-0.8s0.3-0.8,0.8-0.8 H25z M28.8,21.8c0.4,0,0.8,0.3,0.8,0.8s-0.3,0.8-0.8,0.8H20c-0.4,0-0.8-0.3-0.8-0.8s0.3-0.8,0.8-0.8H28.8z M30,26.8 c0.4,0,0.8,0.3,0.8,0.8s-0.3,0.8-0.8,0.8H20c-0.4,0-0.8-0.3-0.8-0.8s0.3-0.8,0.8-0.8H30z M26.2,31.8c0.4,0,0.8,0.3,0.8,0.8 s-0.3,0.8-0.8,0.8l0,0H20c-0.4,0-0.8-0.3-0.8-0.8s0.3-0.8,0.8-0.8H26.2z' id='svg_6'/%3E%3C/g%3E%3C/svg%3E";

    constructor(
        private map: MapComponent,
        private edisLiveDataService: EdisLiveDataService,
        private router: Router,
        private edisOverlayService: EdisOverlayService
    ) {
        this.edisOverlayService.hideObservable.subscribe(() => {
            this.activeId = undefined;
            this.router.navigate([], {
                queryParams: {}
            });
        });
    }

    public render(): Promise<Outage[]> {
        return new Promise((resolve) => {
            this.liveDataSubscription = this.edisLiveDataService.subscribeLiveData((data: ILiveData) => {
                this.outages = data.outages;
                this.update();
                resolve();
            });
        });
    }

    public hide(): void {
        this.liveDataSubscription.unsubscribe();
        this.featureGroup.remove();
    }

    private update(): void {
        this.featureGroup.clearLayers();
        if (this.outages) {
            this.outages.forEach((outage) => {
                this.featureGroup.addLayer(
                    geoJSON(GeoJSON.asFeature(outage.incidents[0].address.geometry), {
                        pointToLayer: (feature, latlng) => {
                            return marker(latlng, {
                                icon: this.getOutageIcon(
                                    outage.voltageType,
                                    outage.status,
                                    this.activeId === outage.id,
                                    this.activeId && this.activeId !== outage.id
                                )
                            }).on('click', (e: Event) => {
                                this.setOutageActive(outage);
                            });
                        }
                    })
                );

                if (outage.incidents.length > 1) {
                    outage.incidents.forEach((incident, index) => {
                        if (index) {
                            this.featureGroup.addLayer(
                                geoJSON(GeoJSON.asFeature(incident.address.geometry), {
                                    pointToLayer: (feature, latlng) => {
                                        return marker(latlng, {
                                            icon: this.getIncidentIcon(
                                                incident.status,
                                                this.activeId === incident.id,
                                                this.activeId && this.activeId !== incident.id
                                            )
                                        }).on('click', (e: Event) => {
                                            this.setIncidentActive(incident);
                                        });
                                    }
                                })
                            );
                        }
                    });
                }

                // TODO: einen circle malen der alle incidents dieser outage umfasst
            });

            this.featureGroup.addTo(this.map);
        }
    }

    private setOutageActive(outage: Outage): void {
        this.activeId = outage.id;
        this.edisOverlayService.show({
            component: OverlayComponentName.DetailsOutage,
            params: { outageId: outage.id }
        });
        this.router.navigate([], {
            queryParams: {
                outageId: outage.id
            }
        });
        this.update();
        this.map.fitBounds(geoJSON(GeoJSON.asFeature(outage.incidents[0].address.geometry)).getBounds(), {
            maxZoom: this.map.getZoom()
        });
    }

    private setIncidentActive(incident: Incident): void {
        this.activeId = incident.id;
        this.edisOverlayService.show({
            component: OverlayComponentName.DetailsIncident,
            params: { incidentId: incident.id }
        });
        this.router.navigate([], {
            queryParams: {
                incidentId: incident.id
            }
        });
        this.update();
        this.map.fitBounds(geoJSON(GeoJSON.asFeature(incident.address.geometry)).getBounds(), {
            maxZoom: this.map.getZoom()
        });
    }

    private getOutageIcon(voltageType: VoltageType, status: Status, isActive: boolean, isDeactivated: boolean): Icon {
        let color;
        let backgroundColor;
        let label = '';

        if (isDeactivated) {
            backgroundColor = 'ffffff';
            if (status === 'DONE') {
                color = '27a737';
            } else if (status === 'PROGRESS') {
                color = 'dc8d0a';
            } else if (status === 'OPEN') {
                color = 'C10119';
            }
        } else {
            color = 'ffffff';
            if (status === 'DONE') {
                backgroundColor = '27a737';
            } else if (status === 'PROGRESS') {
                backgroundColor = 'dc8d0a';
            } else if (status === 'OPEN') {
                backgroundColor = 'C10119';
            }
        }

        if (voltageType === 'LOW') {
            label = 'NS';
        } else if (voltageType === 'MEDIUM') {
            label = 'MS';
        }

        let iconSize = [89, 62];
        let iconAnchor = [0, 62];

        if (isActive) {
            iconSize = [89 * 1.2, 62 * 1.2];
            iconAnchor = [0, 62 * 1.2];
        }

        return icon({
            iconUrl: this.iconUrlOutageTemplate
                .replace(/CCC/g, color)
                .replace('BBB', backgroundColor)
                .replace('LL', label),
            iconSize: [iconSize[0], iconSize[1]],
            iconAnchor: [iconAnchor[0], iconAnchor[1]]
        });
    }

    private getIncidentIcon(status: Status, isActive: boolean, isDeactivated: boolean): Icon {
        let color;
        let backgroundColor;

        if (isDeactivated) {
            backgroundColor = 'ffffff';
            if (status === 'DONE') {
                color = '27a737';
            } else if (status === 'PROGRESS') {
                color = 'dc8d0a';
            } else if (status === 'OPEN') {
                color = 'C10119';
            }
        } else {
            color = 'ffffff';
            if (status === 'DONE') {
                backgroundColor = '27a737';
            } else if (status === 'PROGRESS') {
                backgroundColor = 'dc8d0a';
            } else if (status === 'OPEN') {
                backgroundColor = 'C10119';
            }
        }
        let iconSize = [50, 63];
        let iconAnchor = [0, 63];

        if (isActive) {
            iconSize = [50 * 1.2, 63 * 1.2];
            iconAnchor = [0, 63 * 1.2];
        }

        return icon({
            iconUrl: this.iconUrlIncidentTemplate.replace('00497A', color).replace('BBB', backgroundColor),
            iconSize: [iconSize[0], iconSize[1]],
            iconAnchor: [iconAnchor[0], iconAnchor[1]]
        });
    }
}
