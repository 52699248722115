import { Injectable } from '@angular/core';
import { Subject, Unsubscribable } from 'rxjs';
import { Outage } from '../../models/outage';
import { Incident } from '../../models/incident';
import { environment } from '../../../environments/environment';

export interface ILiveData {
    messageType?: 'all' | 'update';
    outages: Outage[];
    unassignedIncidents: Incident[];
}

const dataTypes = ['outages', 'unassignedIncidents'];

@Injectable()
export class EdisLiveDataService {
    private liveDataSubject = new Subject<any>();
    private liveDataObservable = this.liveDataSubject.asObservable();
    private subscriptionsAmount = 0;
    public socket: WebSocket;
    public liveData: ILiveData = { outages: [], unassignedIncidents: [] };

    public subscribeLiveData(callback: (data: ILiveData) => void): Unsubscribable {
        let subscription: Unsubscribable;
        subscription = this.liveDataObservable.subscribe(callback);
        this.subscriptionsAmount++;
        if (this.subscriptionsAmount === 1) {
            this.openWebsocketConnection();
        } else {
            this.liveDataSubject.next(this.liveData);
        }
        return {
            unsubscribe: () => {
                subscription.unsubscribe();
                this.subscriptionsAmount--;
                if (!this.subscriptionsAmount) {
                    this.closeWebsocketConnection();
                }
            }
        };
    }

    private openWebsocketConnection(): void {
        this.socket = new WebSocket(environment.routes.liveData);
        this.socket.onmessage = (e) => {
            const data = JSON.parse(e.data);
            if (typeof data === 'object' && data.messageType) {
                this.updateLiveData(data);
            } else {
                console.error('Unexpected or missing messageType in Websocket connection');
            }
        };
        this.socket.onerror = (e) => {
            console.error('Unexpected error in Websocket connection');
            console.error(e);
        };
    }

    private updateLiveData(data: ILiveData): void {
        if (data.messageType === 'all') {
            this.liveData.outages = data.outages.filter((o) => {
                return o.incidents.length;
            });
            this.liveData.unassignedIncidents = data.unassignedIncidents;
        } else if (data.messageType === 'update') {
            for (const dataType of dataTypes) {
                if (data[dataType]) {
                    data[dataType].forEach((updated) => {
                        this.liveData[dataType] = this.liveData[dataType].map((current) => {
                            if (current.id === updated.id) {
                                return updated;
                            } else {
                                return current;
                            }
                        });
                    });
                }
            }
        }
        this.liveDataSubject.next(this.liveData);
    }

    private closeWebsocketConnection(): void {
        this.socket.close();
    }
}
