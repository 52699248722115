import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'edis-icon-button',
    templateUrl: './icon-button.component.html',
    styleUrls: ['./icon-button.component.scss']
})
export class EdisIconButtonComponent {
    @Input()
    tooltipText: string;
    @Input()
    iconClass: string;
    @Input()
    borderRadiusDisabled: 'left' | 'right' | 'top' | 'bottom';
    @Input()
    disabled: boolean;
    @Input()
    tooltipDirection: 'left' | 'right' | 'top' | 'bottom' = 'top';
    @Input()
    loading: boolean;
    @Output()
    clicked = new EventEmitter<any>();

    public onClick(event): void {
        this.clicked.emit(event);
    }
}
