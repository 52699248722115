import { Component } from '@angular/core';

/*
 * App Component
 * Top Level Component
 */
@Component({
    selector: 'edis-app',
    template: `
        <router-outlet></router-outlet>
    `
})
export class EdisAppComponent {
    constructor() {}
}
