import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Md5 } from 'ts-md5/dist/md5';

@Injectable({ providedIn: 'root' })
export class EdisAuthenticationService {
    private isLoggedIn: boolean;
    private apiToken: string;

    constructor() {}

    public isUserAuthenticated(): boolean {
        return this.isLoggedIn;
    }

    public getApiToken(): string {
        return this.apiToken;
    }

    public login(username: string, password: string): Promise<boolean> {
        return new Promise((resolve) => {
            if (!username || !password) {
                this.isLoggedIn = false;
                resolve(this.isLoggedIn);
            } else {
                this.isLoggedIn =
                    environment.user_name === Md5.hashStr(username) &&
                    environment.user_password === Md5.hashStr(password);
                if (this.isLoggedIn) {
                    this.apiToken = btoa(username + ':' + password);
                }
                resolve(this.isLoggedIn);
            }
        });
    }

    public logout(): void {
        this.isLoggedIn = false;
    }
}
