// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    qa: false,
    routes: {
        assets: 'https://dev-api.edis-otm.networks.eon.com/api/assets/v1',
        customer: 'https://dev-api.edis-otm.networks.eon.com/api/customer/v1',
        liveData: 'wss://dev-api.edis-otm.networks.eon.com/outages'
    },
    user_name: 'a86cd80391c5ac7f1a224522721a05a3',
    user_password: 'ca9ec56f3d4069832f0e32a486d6748f'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.

// API token:  btoa('edis-otm-outages' + ':' + 'v7dnVW67sU9AC9orTa') = ZWRpcy1vdG0tb3V0YWdlczp2N2RuVlc2N3NVOUFDOW9yVGE=
