import { AfterViewInit, Directive, ElementRef, OnDestroy } from '@angular/core';
@Directive({
    selector: '[edisTrapFocus]'
})
export class EdisTrapFocusDirective implements AfterViewInit, OnDestroy {
    private KEYCODE_TAB = 9;
    private modalContent;
    private firstFocusableEl;
    private lastFocusableEl;

    constructor(private hostElement: ElementRef) {
        this.onKeyDown = this.onKeyDown.bind(this);
    }

    public ngAfterViewInit(): void {
        this.init();
    }

    public ngOnDestroy(): void {
        window.removeEventListener('keydown', this.onKeyDown, false);
    }

    private onKeyDown(e: KeyboardEvent): void {
        // @ts-ignore
        if (e.key === 'Tab' || e.keyCode === this.KEYCODE_TAB) {
            if (e.shiftKey) {
                if (document.activeElement === this.firstFocusableEl) {
                    this.lastFocusableEl.focus();
                    e.preventDefault();
                }
            } else {
                if (document.activeElement === this.lastFocusableEl) {
                    this.firstFocusableEl.focus();
                    e.preventDefault();
                }
            }
        }
    }

    private init(): void {
        if (this.hostElement.nativeElement) {
            this.modalContent = this.hostElement.nativeElement.querySelectorAll(
                'a, [tabindex], input, button, select, textarea'
            );
            this.firstFocusableEl = this.modalContent[0];
            this.lastFocusableEl = this.modalContent[this.modalContent.length - 1];
            this.firstFocusableEl.focus();
            this.firstFocusableEl.blur();
        }
        window.addEventListener('keydown', this.onKeyDown);
    }
}
