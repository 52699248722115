import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { VoltageType } from 'src/app/enums/voltage-type.enum';
import { EdisAuthenticationService } from '../authentication/authentication.service';

@Injectable({
    providedIn: 'root'
})
export class EdisCustomerService {
    constructor(private httpClient: HttpClient, private edisAuthenticationService: EdisAuthenticationService) {}

    private getRequestHeaders(): HttpHeaders {
        return new HttpHeaders().set('Authorization', this.edisAuthenticationService.getApiToken());
    }

    public getCustomer(
        bounds: { west: number; north: number; east: number; south: number },
        voltageType: VoltageType
    ): Observable<Object> {
        return this.httpClient.get(environment.routes.customer, {
            headers: this.getRequestHeaders(),
            params: new HttpParams()
                .set('west', bounds.west.toString())
                .set('north', bounds.north.toString())
                .set('east', bounds.east.toString())
                .set('south', bounds.south.toString())
                .set('voltageType', voltageType)
        });
    }
}
