import { Component, Input } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { featureGroup, FeatureGroup, geoJSON, LatLng, LatLngBounds, marker } from 'leaflet';
import { MapComponent } from '@yaga/leaflet-ng2';
import { EdisNotificationService } from '../../services/notification/notification.service';
import { EdisNominatimService } from '../../services/nominatim/nominatim.service';
import { iconSearchResult } from '../../leaflet-styles/icons';
import { polygonSearchResult } from '../../leaflet-styles/polygons';
import { popupOptionsSearchResult } from '../../leaflet-styles/popups';
import { lineStringSearchResult } from '../../leaflet-styles/line-strings';
import * as turf from '@turf/turf';
import * as configuration from '../../../config.json';

@Component({
    selector: 'edis-map-address-search',
    templateUrl: './map-address-search.component.html',
    styleUrls: ['./map-address-search.component.scss']
})
export class EdisMapAddressSearchComponent {
    @Input() mapObject: MapComponent;
    @Input() maxBounds: LatLngBounds;
    @Input() parentHeight: number;
    public isLoading: boolean;
    public selectedSearchResultIndex: number;
    public notFound: boolean;
    public search: string;
    public isResultLayerOpen: boolean;
    public searchResults: any[];
    private place: FeatureGroup;
    private searchResultProtected: boolean;
    private searchResultProtectedTimer: ReturnType<typeof setTimeout>;

    constructor(
        private httpClient: HttpClient,
        private edisNotificationService: EdisNotificationService,
        private edisNominatimService: EdisNominatimService
    ) {}

    private static getLeafletStyle(type: string): any {
        switch (type) {
            case 'Polygon': {
                return polygonSearchResult;
            }
            case 'LineString': {
                return lineStringSearchResult;
            }
            default: {
                return {};
            }
        }
    }

    private static getFormattedAddress(searchResult: any): string {
        let formattedAddress = '';

        if (searchResult.address) {
            if (searchResult.address.road) {
                formattedAddress += searchResult.address.road;
                if (searchResult.address.house_number) {
                    formattedAddress += ' ' + searchResult.address.house_number;
                }
            }
            if (searchResult.address.postcode) {
                if (formattedAddress) {
                    formattedAddress += '<br>';
                }
                formattedAddress += searchResult.address.postcode;
            }
            if (searchResult.address.town || searchResult.address.village || searchResult.address.city_district) {
                if (formattedAddress && !searchResult.address.postcode) {
                    formattedAddress += '<br>';
                }
                if (searchResult.address.postcode) {
                    formattedAddress += ' ';
                }
                formattedAddress += searchResult.address.town
                    ? searchResult.address.town
                    : searchResult.address.village
                    ? searchResult.address.village
                    : searchResult.address.city_district;
            }
        } else if (searchResult.display_name) {
            formattedAddress = searchResult.display_name;
        } else {
            formattedAddress = '???';
        }

        return formattedAddress;
    }

    public searchForPlaces(): boolean {
        let searchFinished = false;
        setTimeout(() => {
            if (!searchFinished) {
                this.isLoading = true;
            }
        }, 250);

        this.resetSearchResult();
        this.search = this.search ? this.search.trim() : '';
        if (this.search) {
            this.edisNominatimService.getPlaces(this.search, this.maxBounds).then(
                (response: any) => {
                    searchFinished = true;
                    this.isLoading = false;
                    const validClasses = ['highway', 'building', 'shop', 'place'];
                    const invalidTypes = ['bus_stop'];
                    const result = response.filter(
                        (place) =>
                            place.geojson &&
                            validClasses.indexOf(place.class) !== -1 &&
                            invalidTypes.indexOf(place.type) === -1 &&
                            configuration.default.postalCodes.indexOf(place.address.postcode) !== -1 &&
                            (!place.address.city_district ||
                                configuration.default.cityDisctricts.indexOf(place.address.city_district) !== -1)
                    );
                    this.searchResults = [];
                    result.forEach((s) => {
                        s.addressFormatted = EdisMapAddressSearchComponent.getFormattedAddress(s);
                        this.searchResults.push(s);
                    });
                    if (this.searchResults.length) {
                        this.searchResults.reverse();
                        this.showPlace(
                            this.searchResults[this.searchResults.length - 1],
                            this.searchResults.length - 1
                        );
                        if (this.searchResults.length > 1) {
                            this.isResultLayerOpen = true;

                            setTimeout(() => {
                                const lastSearchResultElement = document.querySelector(
                                    '.edis-map-address-search .results ul li:last-child'
                                );
                                if (lastSearchResultElement) {
                                    lastSearchResultElement.scrollIntoView({ behavior: 'auto', block: 'end' });
                                }
                            }, 250);
                        }
                    } else {
                        this.noResult();
                    }
                },
                () => {
                    // error
                    searchFinished = true;
                    this.isLoading = false;
                    this.edisNotificationService.showNotification(
                        'Fehler',
                        'Es ist ein unerwarteter Fehler aufgetreten, bitte versuchen Sie es später noch einmal.'
                    );
                }
            );
        } else {
            searchFinished = true;
            this.noResult();
        }

        return false;
    }

    public showPlace(searchResult: any, index: number): void {
        // protect the search result from being directly removed for a given time
        this.searchResultProtected = true;
        if (this.searchResultProtectedTimer) {
            clearTimeout(this.searchResultProtectedTimer);
        }
        this.searchResultProtectedTimer = setTimeout(() => {
            this.searchResultProtected = false;
        }, 1000);

        if (this.place) {
            this.place.remove();
        }
        this.place = featureGroup();

        this.place.addLayer(
            geoJSON(searchResult.geojson, {
                style: () => EdisMapAddressSearchComponent.getLeafletStyle(searchResult.geojson.type),
                pointToLayer: (feature, latlng) => {
                    return marker(latlng, {
                        icon: iconSearchResult
                    });
                },
                onEachFeature: (feature, layer) => {
                    layer.bindPopup(searchResult.addressFormatted, popupOptionsSearchResult);
                    if (feature.type.toString() === 'Polygon' || feature.type.toString() === 'LineString') {
                        let center;
                        if (feature.type.toString() === 'Polygon') {
                            // @ts-ignore
                            const bounds = layer.getBounds();
                            center = bounds.getCenter();
                        } else if (feature.type.toString() === 'LineString') {
                            // @ts-ignore
                            const lineString = turf.lineString(feature.coordinates);
                            const length = turf.length(lineString);
                            const middle = turf.along(lineString, length / 2);
                            center = new LatLng(middle.geometry.coordinates[1], middle.geometry.coordinates[0]);
                        }

                        if (center) {
                            this.place.addLayer(
                                marker(center, {
                                    icon: iconSearchResult
                                })
                                    .addTo(this.mapObject)
                                    .bindPopup(searchResult.addressFormatted, popupOptionsSearchResult)
                            );
                        }
                    }
                }
            })
        );
        this.mapObject.fitBounds(this.place.getBounds(), { maxZoom: configuration.default.map.zoom.max });
        this.place.addTo(this.mapObject);
        this.selectedSearchResultIndex = index;
    }

    public onClickOutside(): void {
        this.closeResultLayer();
    }

    public closeResultLayer(): void {
        this.isResultLayerOpen = false;
    }

    public mapMoved() {
        if (!this.searchResultProtected) {
            this.resetSearchResult();
        }
    }

    private resetSearchResult(): void {
        this.selectedSearchResultIndex = undefined;
        this.notFound = false;
        this.isResultLayerOpen = false;
        this.searchResults = undefined;
        if (this.place) {
            this.place.remove();
        }
    }

    private noResult(): void {
        this.notFound = true;
        setTimeout(() => {
            this.notFound = false;
        }, 1000);
    }
}
