import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'edis-button',
    templateUrl: './button.component.html',
    styleUrls: ['./button.component.scss']
})
export class EdisButtonComponent {
    @Input()
    label: string;
    @Input()
    disabled: boolean;
    @Input()
    iconClass: string;
    @Output()
    clicked = new EventEmitter<any>();

    public onClick(): void {
        this.clicked.emit();
    }
}
