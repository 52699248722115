import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { Outage } from '../../models/outage';
import { EdisLiveDataService, ILiveData } from '../../services/live-data/live-data.service';
import { Unsubscribable } from 'rxjs';
import { Router } from '@angular/router';
import { Incident } from '../../models/incident';

@Component({
    selector: 'edis-details-incident',
    templateUrl: './details-incident.component.html',
    styleUrls: ['./details-incident.component.scss']
})
export class EdisDetailsIncidentComponent implements OnInit, OnDestroy, OnChanges {
    @Input()
    incidentId: number;
    public outages: Outage[];
    public unassignedIncidents: Incident[];
    public currentIncident: Incident;
    public relevantIncidents: Incident[];
    public parentOutagePrimaryIncident: Incident;
    private liveDataSubscription: Unsubscribable;

    constructor(private edisLiveDataService: EdisLiveDataService, private router: Router) {}

    public showOutage(outageId: number) {
        alert('Funktion noch nicht implementiert.');
        // TODO: show outage
    }

    public changeIncident(direction: 'previous' | 'next'): void {
        let newIndex: number;

        const currentIndex = this.relevantIncidents.findIndex((incident) => {
            return incident.id === this.currentIncident.id;
        });

        if (direction === 'next') {
            if (currentIndex === this.relevantIncidents.length - 1) {
                newIndex = 0;
            } else {
                newIndex = currentIndex + 1;
            }
        } else if (direction === 'previous') {
            if (currentIndex === 0) {
                newIndex = this.relevantIncidents.length - 1;
            } else {
                newIndex = currentIndex - 1;
            }
        }
        this.currentIncident = this.relevantIncidents[newIndex];

        this.router.navigate([], {
            queryParams: {
                incidentId: this.currentIncident.id
            }
        });
    }

    public showHistory() {
        alert('Funktion noch nicht implementiert.');
        // TODO: show history
    }

    public ngOnInit(): void {
        this.liveDataSubscription = this.edisLiveDataService.subscribeLiveData((data: ILiveData) => {
            this.outages = data.outages;
            this.unassignedIncidents = data.unassignedIncidents;
            this.setIncident();
        });
    }

    public ngOnDestroy(): void {
        this.liveDataSubscription.unsubscribe();
    }

    public ngOnChanges(changes: SimpleChanges) {
        if (changes.incidentId.previousValue !== changes.incidentId.currentValue) {
            this.setIncident();
        }
    }

    private setIncident() {
        this.currentIncident = undefined;
        this.parentOutagePrimaryIncident = undefined;
        // search in outages
        if (this.outages) {
            outerLoop: for (let oIndex = 0; oIndex < this.outages.length; oIndex++) {
                for (let iIndex = 0; iIndex < this.outages[oIndex].incidents.length; iIndex++) {
                    if (this.outages[oIndex].incidents[iIndex].id === this.incidentId) {
                        this.currentIncident = this.outages[oIndex].incidents[iIndex];
                        this.relevantIncidents = this.outages[oIndex].incidents;
                        this.parentOutagePrimaryIncident = this.outages[oIndex].incidents[0];
                        break outerLoop;
                    }
                }
            }
        }
        // search in unassigned incidents if still undefined
        if (!this.currentIncident && this.unassignedIncidents) {
            for (let i = 0; i < this.unassignedIncidents.length; i++) {
                if (this.unassignedIncidents[i].id === this.incidentId) {
                    this.currentIncident = this.unassignedIncidents[i];
                    this.relevantIncidents = this.unassignedIncidents;
                    break;
                }
            }
        }
    }
}
