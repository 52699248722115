import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

export enum MapLayerType {
    AssetsLow = 'ASSETS_LOW',
    AssetsMedium = 'ASSETS_MEDIUM',
    Outages = 'OUTAGES',
    IncidentsUnassigned = 'INCIDENTS_UNASSIGNED',
    Customer = 'CUSTOMER'
}

@Injectable()
export class EdisMapService {
    public layerViewSource = new Subject<{ type: MapLayerType; isVisible: boolean }>();
    public layerViewListener = this.layerViewSource.asObservable();

    private layerVisibility = {
        ASSETS_LOW: false,
        ASSETS_MEDIUM: false,
        OUTAGES: true,
        CUSTOMER: false
    };

    public updateLayerView(type: MapLayerType, isVisible: boolean): void {
        this.layerVisibility[type] = isVisible;
        this.layerViewSource.next({ type, isVisible });
    }

    public isLayerVisible(type: MapLayerType): boolean {
        return this.layerVisibility[type];
    }
}
