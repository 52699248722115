import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'edis-layer-setting-button',
    templateUrl: './layer-setting-button.component.html',
    styleUrls: ['./layer-setting-button.component.scss']
})
export class EdisLayerSettingButtonComponent {
    @Input()
    label: string;
    @Input()
    iconClass: string;
    @Input()
    isActive: boolean;
    @Output()
    changed = new EventEmitter<any>();

    public onSwitchChanged(switchValue: boolean) {
        this.isActive = switchValue;
        this.changed.emit(switchValue);
    }
}
