import { Component } from '@angular/core';
import { EdisAuthenticationService } from '../../services/authentication/authentication.service';
import { Router } from '@angular/router';

@Component({
    selector: 'edis-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class EdisLoginComponent {
    public username: string;
    public password: string;
    public isError: boolean;

    constructor(private edisAuthenticationService: EdisAuthenticationService, private router: Router) {}

    public submitLogin() {
        this.isError = false;
        this.edisAuthenticationService.login(this.username, this.password).then((isLoggedIn) => {
            if (isLoggedIn) {
                this.router.navigate(['/']);
            } else {
                this.isError = true;
            }
        });
    }
}
