import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { v4 as uuid } from 'uuid';

@Component({
    selector: 'edis-switch',
    templateUrl: './switch.component.html',
    styleUrls: ['./switch.component.scss']
})
export class EdisSwitchComponent {
    @Input()
    isActive: boolean;
    @Output()
    changed = new EventEmitter<any>();

    public uniqueId = uuid();

    public onChanged(): void {
        this.changed.emit(this.isActive);
    }
}
