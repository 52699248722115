import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class EdisNotificationService {
    public notificationSource = new Subject<{ headline: string; message: string }>();
    public notificationListener = this.notificationSource.asObservable();

    /**
     * Show notification
     */
    public showNotification(headline: string, message: string): void {
        this.notificationSource.next({ headline, message });
    }
}
