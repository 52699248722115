import { LatLng, MapComponent } from '@yaga/leaflet-ng2';
import { EdisCustomerService } from '../../../services/customer/customer.service';
import { VoltageType } from '../../../enums/voltage-type.enum';
import { featureGroup, geoJSON, circleMarker, FeatureGroup, GeoJSON, Layer } from 'leaflet';
import { Unsubscribable } from 'rxjs';
import * as geojson from 'geojson';
import { EdisOverlayService, OverlayComponentName } from '../../../services/overlay/overlay.service';
import { Customer } from '../../../models/customer';

export class MapLayerCustomer {
    private req: Unsubscribable;
    private layer: FeatureGroup[] = [];
    private currentLayerIndex = 0;
    private cancelled = false;
    private activeIndex: number;
    private customers: Customer[];

    constructor(
        private map: MapComponent,
        private voltageType: VoltageType,
        private edisCustomerService: EdisCustomerService,
        private edisOverlayService: EdisOverlayService
    ) {
        this.layer[0] = featureGroup();
        this.layer[1] = featureGroup();

        this.edisOverlayService.hideObservable.subscribe(() => {
            this.activeIndex = undefined;
            this.update();
        });
    }

    public render(): Promise<undefined> {
        this.cancelled = false;
        return new Promise((resolve, reject) => {
            const currentBounds = this.map.getBounds();
            if (this.req) {
                this.req.unsubscribe();
            }
            this.req = this.edisCustomerService
                .getCustomer(
                    {
                        west: currentBounds.getWest(),
                        south: currentBounds.getSouth(),
                        north: currentBounds.getNorth(),
                        east: currentBounds.getEast()
                    },
                    this.voltageType
                )
                .subscribe(
                    (response: Customer[]) => {
                        if (!this.cancelled) {
                            this.customers = response;
                            this.update();
                        }
                        resolve();
                    },
                    (e) => {
                        console.log(e);
                        this.hide();
                        reject();
                    }
                );
        });
    }

    private update(): void {
        const newLayerIndex = this.currentLayerIndex === 0 ? 1 : 0;
        this.layer[newLayerIndex].clearLayers();

        this.customers.forEach((customer: Customer, index: number) => {
            let color = '#27a737';
            let weight = 2;
            if (index === this.activeIndex) {
                color = '#27a737';
                weight = 4;
            }

            this.layer[newLayerIndex].addLayer(
                geoJSON(GeoJSON.asFeature(customer.address.geometry), {
                    style: () => {
                        return {
                            color: color,
                            weight: weight
                        };
                    },
                    onEachFeature: (feature: geojson.Feature<geojson.GeometryObject>, layer: Layer) => {
                        layer.on({
                            click: () => {
                                this.setActive(customer, index);
                            }
                        });
                    },
                    pointToLayer: (feature: geojson.Feature<geojson.GeometryObject>, latlng: LatLng) => {
                        return circleMarker(latlng, {
                            radius: 8,
                            fillColor: '#00497a',
                            color: '#00497a',
                            weight: 1,
                            opacity: 1,
                            fillOpacity: 0.2
                        });
                    }
                })
            );
        });

        this.layer[newLayerIndex].addTo(this.map);
        this.layer[this.currentLayerIndex].clearLayers();
        this.layer[this.currentLayerIndex].remove();
        this.currentLayerIndex = newLayerIndex;
    }

    public hide(): void {
        this.cancelled = true;
        this.layer[0].remove();
        this.layer[1].remove();
    }

    private setActive(customer: Customer, index: number): void {
        this.activeIndex = index;
        this.edisOverlayService.show({
            component: OverlayComponentName.DetailsCustomer,
            params: { customer: customer }
        });
        this.map.fitBounds(geoJSON(GeoJSON.asFeature(customer.address.geometry)).getBounds(), {
            maxZoom: this.map.getZoom()
        });
    }
}
