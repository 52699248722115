import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { EdisOverlayService, OverlayComponentName } from '../../services/overlay/overlay.service';
import { Subscription } from 'rxjs';

@Component({
    selector: 'edis-overlay',
    templateUrl: './overlay.component.html',
    styleUrls: ['./overlay.component.scss']
})
export class EdisOverlayComponent implements OnInit, OnDestroy {
    @ViewChild('closeIcon') closeIcon: ElementRef;
    public isOverlayOpen: boolean;
    public isShaking: boolean;
    public overlayComponentNameEnum = OverlayComponentName;
    public componentName: OverlayComponentName;
    public componentParams: any;
    private subscriptions: Subscription[] = [];

    constructor(private edisOverlayService: EdisOverlayService) {}

    public hide(): void {
        this.edisOverlayService.hide();
    }

    public ngOnInit(): void {
        const subscriptionShow = this.edisOverlayService.showObservable.subscribe((data) => {
            this.componentName = data.component;
            this.componentParams = data.params;
            this.show();
        });
        this.subscriptions.push(subscriptionShow);

        const subscriptionHide = this.edisOverlayService.hideObservable.subscribe(() => {
            this.isOverlayOpen = false;
        });
        this.subscriptions.push(subscriptionHide);

        const subscriptionShake = this.edisOverlayService.shakeObservable.subscribe(() => {
            this.shake();
        });
        this.subscriptions.push(subscriptionShake);
    }

    public ngOnDestroy(): void {
        for (const subscription of this.subscriptions) {
            subscription.unsubscribe();
        }
    }

    private show(): void {
        this.isOverlayOpen = true;
        setTimeout(() => {
            document.querySelector('.edis-overlay .content-wrapper').scrollTop = 0;
            setTimeout(() => {
                this.closeIcon.nativeElement.focus();
                // noinspection TypeScriptValidateJSTypes
                this.closeIcon.nativeElement.blur();
            }, 0);
        }, 300);
    }

    private shake(): void {
        setTimeout(() => {
            this.isShaking = true;
            setTimeout(() => {
                this.isShaking = false;
            }, 1000);
        }, 0);
    }
}
