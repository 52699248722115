import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { EdisMainComponent } from './components/main/main.component';
import { EdisLoginComponent } from './components/login/login.component';
import { AuthGuard } from './auth.guard';

export const routes: Routes = [
    { path: '', component: EdisMainComponent, pathMatch: 'full', canActivate: [AuthGuard] },
    { path: 'login', component: EdisLoginComponent }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {}
