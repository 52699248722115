import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GeoJSON, LatLngBounds } from 'leaflet';

@Injectable({
    providedIn: 'root'
})
export class EdisNominatimService {
    constructor(private httpClient: HttpClient) {}

    public getPlaces(search: string, maxBounds: LatLngBounds): Promise<any> {
        return new Promise((resolve, reject) => {
            const url =
                'https://nominatim.openstreetmap.org/search.php?q=' +
                search +
                '&viewbox=' +
                maxBounds.getWest() +
                ',' +
                maxBounds.getNorth() +
                ',' +
                maxBounds.getEast() +
                ',' +
                maxBounds.getSouth() +
                '&bounded=1' +
                '&addressdetails=1' +
                '&polygon_geojson=1&' +
                'format=json&' +
                'limit=10';

            this.httpClient.get(url).subscribe(
                (response: any) => {
                    resolve(response);
                },
                (err) => {
                    reject(err);
                }
            );
        });
    }

    public getGeoJson(
        postalCode: string,
        city: string,
        streetName: string,
        houseNumber: string,
        maxBounds: LatLngBounds
    ): Promise<GeoJSON> {
        return new Promise((resolve, reject) => {
            const url =
                'https://nominatim.openstreetmap.org/search.php?' +
                'street=' +
                houseNumber +
                ' ' +
                streetName +
                '&postalcode=' +
                postalCode +
                '&city=' +
                city +
                '&viewbox=' +
                maxBounds.getWest() +
                ',' +
                maxBounds.getNorth() +
                ',' +
                maxBounds.getEast() +
                ',' +
                maxBounds.getSouth() +
                '&bounded=1' +
                '&addressdetails=1' +
                '&polygon_geojson=1&' +
                'format=json&' +
                'limit=1';

            this.httpClient.get(url).subscribe(
                (response: any[]) => {
                    if (!response.length) {
                        reject(new Error('NOT_FOUND'));
                    } else if (!response[0].geojson) {
                        reject(new Error('NO_GEOJSON'));
                    } else if (!response[0].address) {
                        reject(new Error('NO_ADDRESS'));
                    } else if (!response[0].address.house_number) {
                        reject(new Error('NO_HOUSENUMBER'));
                    } else if (response[0].address.postcode !== postalCode) {
                        reject(new Error('WRONG_POSTALCODE'));
                    } else {
                        resolve(response[0].geojson);
                    }
                },
                (e) => {
                    reject(e);
                }
            );
        });
    }
}
