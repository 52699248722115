import {
    AfterContentInit,
    ChangeDetectorRef,
    Component,
    ElementRef,
    HostListener,
    OnDestroy,
    OnInit,
    ViewChild
} from '@angular/core';
import { LatLng, LatLngBounds } from 'leaflet';
import { MapComponent } from '@yaga/leaflet-ng2';
import { EdisAssetsService } from '../../services/assets/assets.service';
import { EdisCustomerService } from '../../services/customer/customer.service';
import { EdisNotificationService } from '../../services/notification/notification.service';
import * as configuration from '../../../config.json';
import { EdisMapAddressSearchComponent } from '../map-address-search/map-address-search.component';
import { EdisMapService, MapLayerType } from '../../services/map/map.service';
import { MapLayerAssets } from './layer/map-layer-assets';
import { MapLayerCustomer } from './layer/map-layer-customer';
import { MapLayerOutages } from './layer/map-layer-outages';
import { VoltageType } from '../../enums/voltage-type.enum';
import { EdisLiveDataService } from '../../services/live-data/live-data.service';
import { Router } from '@angular/router';
import { EdisOverlayService, OverlayComponentName } from '../../services/overlay/overlay.service';
import { Subscription } from 'rxjs';
import { MapLayerIncidentsUnassigned } from './layer/map-layer-incidents-unassigned';

@Component({
    selector: 'edis-map',
    templateUrl: './map.component.html',
    styleUrls: ['./map.component.scss']
})
export class EdisMapComponent implements OnInit, AfterContentInit, OnDestroy {
    @ViewChild(MapComponent) leafletMap: MapComponent;
    @ViewChild('edisMap')
    edisMapElement: ElementRef;
    @ViewChild(EdisMapAddressSearchComponent)
    mapAddressSearchComponent: EdisMapAddressSearchComponent;

    public isLoading;
    public isLocationLoading: boolean;
    public isLocationError: boolean;
    public mapTileLayerUrl = '//{s}.tile.openstreetmap.org/{z}/{x}/{y}.png';
    public minMaxReached: boolean;
    public mapComponentHeight: number;
    public maxBounds: LatLngBounds = new LatLngBounds(
        new LatLng(configuration.default.map.maxBounds.north, configuration.default.map.maxBounds.west),
        new LatLng(configuration.default.map.maxBounds.south, configuration.default.map.maxBounds.east)
    );
    private minZoom = configuration.default.map.zoom.min;
    private maxZoom = configuration.default.map.zoom.max;
    private ultraZoom = configuration.default.map.zoom.ultra;
    private maxZoomAssets = configuration.default.map.zoom.max - 1;
    private maxZoomCustomer = configuration.default.map.zoom.max - 1;
    private subscription: Subscription;
    private mapLayer: any = {};

    @HostListener('window:resize', ['$event']) onResize() {
        this.updateEdisMapHeight();
    }

    constructor(
        private changeDetectorRef: ChangeDetectorRef,
        private edisAssetsService: EdisAssetsService,
        private edisCustomerService: EdisCustomerService,
        private edisNotificationService: EdisNotificationService,
        private edisMapService: EdisMapService,
        private edisLiveDataService: EdisLiveDataService,
        private router: Router,
        private edisOverlayService: EdisOverlayService
    ) {}

    public showMapLayerSettings(): void {
        this.edisOverlayService.show({ component: OverlayComponentName.MapLayerSettings });
    }

    public initMap(): void {
        this.leafletMap.setView(
            new LatLng(configuration.default.map.initalPosition.lat, configuration.default.map.initalPosition.lng),
            configuration.default.map.initialZoom
        );
        this.leafletMap.setMaxBounds(this.maxBounds);
        this.leafletMap.setMaxZoom(this.maxZoom);
        this.leafletMap.setMinZoom(this.minZoom);
    }

    public showLocation(): void {
        this.isLocationLoading = true;
        navigator.geolocation.getCurrentPosition(
            (location) => {
                this.isLocationLoading = false;
                const latlng = new LatLng(location.coords.latitude, location.coords.longitude);
                if (!this.maxBounds.contains(latlng)) {
                    this.locationError();
                    this.edisNotificationService.showNotification(
                        'Hinweis',
                        'Ihre Position befindet sich nicht im definierten Kartenausschnitt.'
                    );
                } else {
                    this.leafletMap.setView(latlng, this.leafletMap.getZoom(), {
                        animate: true
                    });
                }
            },
            () => {
                this.locationError();
                this.edisNotificationService.showNotification(
                    'Hinweis',
                    'Sie müssen die Standortfreigabe in ihrem Browser aktivieren.'
                );
                this.isLocationLoading = false;
            }
        );
    }

    public zoomIn(): void {
        this.minMaxReached = false;
        const currentZoom = this.leafletMap.getZoom();
        let maxZoom = this.maxZoom;
        if (
            this.edisMapService.isLayerVisible(MapLayerType.AssetsLow) ||
            this.edisMapService.isLayerVisible(MapLayerType.AssetsMedium)
        ) {
            maxZoom = this.ultraZoom;
        }
        if (currentZoom === maxZoom) {
            setTimeout(() => {
                this.minMaxReached = true;
                setTimeout(() => {
                    this.minMaxReached = false;
                }, 1000);
            }, 0);
        } else {
            this.leafletMap.setZoom(currentZoom + 1);
        }
    }

    public zoomOut(): void {
        this.minMaxReached = false;
        const currentZoom = this.leafletMap.getZoom();
        if (currentZoom === this.minZoom) {
            setTimeout(() => {
                this.minMaxReached = true;
                setTimeout(() => {
                    this.minMaxReached = false;
                }, 1000);
            }, 0);
        } else {
            this.leafletMap.setZoom(currentZoom - 1);
        }
    }

    public onMoveEnd(): void {
        if (this.mapAddressSearchComponent) {
            this.mapAddressSearchComponent.mapMoved();
        }
        // update assets if visible & zoom level is ok
        if (this.leafletMap.getZoom() >= this.maxZoomAssets) {
            if (this.edisMapService.isLayerVisible(MapLayerType.AssetsLow)) {
                this.renderLayer(MapLayerType.AssetsLow);
            }
            if (this.edisMapService.isLayerVisible(MapLayerType.AssetsMedium)) {
                this.renderLayer(MapLayerType.AssetsMedium);
            }
        }
        if (this.leafletMap.getZoom() >= this.maxZoomCustomer) {
            if (this.edisMapService.isLayerVisible(MapLayerType.Customer)) {
                this.renderLayer(MapLayerType.Customer);
            }
        }
        // add additional bounds-specific layer types here
    }

    public onZoomEnd(): void {
        // disable assets if zoom level is to low
        if (this.leafletMap.getZoom() < this.maxZoomAssets) {
            if (this.edisMapService.isLayerVisible(MapLayerType.AssetsLow)) {
                this.edisMapService.updateLayerView(MapLayerType.AssetsLow, false);
            }
            if (this.edisMapService.isLayerVisible(MapLayerType.AssetsMedium)) {
                this.edisMapService.updateLayerView(MapLayerType.AssetsMedium, false);
            }
        }
        if (this.leafletMap.getZoom() < this.maxZoomCustomer) {
            if (this.edisMapService.isLayerVisible(MapLayerType.Customer)) {
                this.edisMapService.updateLayerView(MapLayerType.Customer, false);
            }
        }
    }

    public ngOnInit(): void {
        this.subscription = this.edisMapService.layerViewListener.subscribe(
            (layer: { type: MapLayerType; isVisible: boolean }) => {
                this.toggleLayer(layer.type, layer.isVisible);
                // set maxZoom to ultraZoom if an assetLayer is enabled
                if (
                    this.edisMapService.isLayerVisible(MapLayerType.AssetsLow) ||
                    this.edisMapService.isLayerVisible(MapLayerType.AssetsMedium)
                ) {
                    this.leafletMap.setMaxZoom(this.ultraZoom);
                } else {
                    this.leafletMap.setMaxZoom(this.maxZoom);
                }
            }
        );

        this.toggleLayer(MapLayerType.Outages, true);
    }

    public ngAfterContentInit(): void {
        this.initMap();
        this.updateEdisMapHeight();
    }

    public ngOnDestroy() {
        this.subscription.unsubscribe();

        const mapLayerObjectKeys = Object.keys(this.mapLayer);
        for (const mapLayerObjectKey of mapLayerObjectKeys) {
            this.mapLayer[mapLayerObjectKey].hide();
        }
    }

    private updateEdisMapHeight(): void {
        this.mapComponentHeight = parseInt(this.edisMapElement.nativeElement.offsetHeight, 10);
    }

    private locationError(): void {
        setTimeout(() => {
            this.isLocationError = true;
            setTimeout(() => {
                this.isLocationError = false;
            }, 1000);
        }, 1);
    }

    private toggleLayer(type: MapLayerType, isVisible: boolean): void {
        if (!this.mapLayer[type]) {
            // generate layer object if it not already exists
            if (type === MapLayerType.AssetsLow) {
                this.mapLayer[type] = new MapLayerAssets(this.leafletMap, VoltageType.Low, this.edisAssetsService);
            } else if (type === MapLayerType.AssetsMedium) {
                this.mapLayer[type] = new MapLayerAssets(this.leafletMap, VoltageType.Medium, this.edisAssetsService);
            } else if (type === MapLayerType.Customer) {
                this.mapLayer[type] = new MapLayerCustomer(
                    this.leafletMap,
                    VoltageType.Medium,
                    this.edisCustomerService,
                    this.edisOverlayService
                );
            } else if (type === MapLayerType.Outages) {
                this.mapLayer[type] = new MapLayerOutages(
                    this.leafletMap,
                    this.edisLiveDataService,
                    this.router,
                    this.edisOverlayService
                );
            } else if (type === MapLayerType.IncidentsUnassigned) {
                this.mapLayer[type] = new MapLayerIncidentsUnassigned(
                    this.leafletMap,
                    this.edisLiveDataService,
                    this.router,
                    this.edisOverlayService
                );
            }
            // add additional layer types here
        }
        if (!isVisible) {
            // hide layer
            this.mapLayer[type].hide();
        } else {
            // show layer
            if (type === MapLayerType.AssetsMedium || type === MapLayerType.AssetsLow) {
                if (this.leafletMap.getZoom() >= this.maxZoomAssets) {
                    this.renderLayer(type);
                } else {
                    this.leafletMap.setZoom(this.maxZoomAssets);
                    // rendering would be triggered by onMoveEnd event, which is triggered by setZoom above
                }
                // add additional bounds-specific layer types here
            } else if (type === MapLayerType.Customer) {
                if (this.leafletMap.getZoom() >= this.maxZoomCustomer) {
                    this.renderLayer(type);
                } else {
                    this.leafletMap.setZoom(this.maxZoomCustomer);
                    // rendering would be triggered by onMoveEnd event, which is triggered by setZoom above
                }
            } else {
                this.renderLayer(type);
            }
        }
    }

    private renderLayer(type: MapLayerType): void {
        this.isLoading = true;
        this.mapLayer[type]
            .render()
            .then(() => {
                this.isLoading = false;
            })
            .catch(() => {
                this.isLoading = false;
                this.edisNotificationService.showNotification(
                    'Hinweis',
                    'Es ist ein unerwarteter Fehler aufgetreten, bitte versuchen Sie es später noch einmal.'
                );
            });
    }
}
