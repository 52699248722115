import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { Outage } from '../../models/outage';
import { EdisLiveDataService, ILiveData } from '../../services/live-data/live-data.service';
import { Unsubscribable } from 'rxjs';
import { Router } from '@angular/router';

@Component({
    selector: 'edis-details-outage',
    templateUrl: './details-outage.component.html',
    styleUrls: ['./details-outage.component.scss']
})
export class EdisDetailsOutageComponent implements OnInit, OnDestroy, OnChanges {
    @Input()
    outageId: number;
    public outages: Outage[];
    public currentOutage: Outage;

    private outagesSubscription: Unsubscribable;

    constructor(private edisLiveDataService: EdisLiveDataService, private router: Router) {}

    public showIncident(incidentId: number) {
        alert('Funktion noch nicht implementiert.');
        // TODO: show incident
    }

    public changeOutage(direction: 'previous' | 'next'): void {
        let newIndex: number;

        const currentIndex = this.outages.findIndex((o) => {
            return o.id === this.currentOutage.id;
        });

        if (direction === 'next') {
            if (currentIndex === this.outages.length - 1) {
                newIndex = 0;
            } else {
                newIndex = currentIndex + 1;
            }
        } else if (direction === 'previous') {
            if (currentIndex === 0) {
                newIndex = this.outages.length - 1;
            } else {
                newIndex = currentIndex - 1;
            }
        }
        this.currentOutage = this.outages[newIndex];

        this.router.navigate([], {
            queryParams: {
                outageId: this.currentOutage.id
            }
        });
    }

    public showHistory() {
        alert('Funktion noch nicht implementiert.');
        // TODO: show history
    }

    public ngOnInit(): void {
        this.outagesSubscription = this.edisLiveDataService.subscribeLiveData((data: ILiveData) => {
            this.outages = data.outages;
            this.setOutage();
        });
    }

    public ngOnDestroy(): void {
        this.outagesSubscription.unsubscribe();
    }

    public ngOnChanges(changes: SimpleChanges) {
        if (changes.outageId.previousValue !== changes.outageId.currentValue) {
            this.setOutage();
        }
    }

    private setOutage() {
        if (this.outages) {
            this.currentOutage = this.outages.find((o) => o.id === this.outageId);
        } else {
            this.currentOutage = undefined;
        }
    }
}
