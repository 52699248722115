import { MapComponent } from '@yaga/leaflet-ng2';
import { EdisAssetsService } from '../../../services/assets/assets.service';
import { VoltageType } from '../../../enums/voltage-type.enum';
import { featureGroup, geoJSON, circleMarker, FeatureGroup } from 'leaflet';
import { lineStringAssetLow, lineStringAssetMedium } from '../../../leaflet-styles/line-strings';
import { popupOptionsAssets } from '../../../leaflet-styles/popups';
import { Unsubscribable } from 'rxjs';
import { circleSubstation } from '../../../leaflet-styles/circles';

export class MapLayerAssets {
    private req: Unsubscribable;
    private layer: FeatureGroup[] = [];
    private currentLayerIndex = 0;
    private cancelled = false;
    private static getLeafletStyle(type: string, voltageType: VoltageType): any {
        switch (type + voltageType) {
            case 'LineStringLOW': {
                return lineStringAssetLow;
            }
            case 'LineStringMEDIUM': {
                return lineStringAssetMedium;
            }
            default: {
                return {};
            }
        }
    }

    constructor(
        private map: MapComponent,
        private voltageType: VoltageType,
        private edisAssetsService: EdisAssetsService
    ) {
        this.layer[0] = featureGroup();
        this.layer[1] = featureGroup();
    }

    public render(): Promise<undefined> {
        this.cancelled = false;
        return new Promise((resolve, reject) => {
            const currentBounds = this.map.getBounds();
            if (this.req) {
                this.req.unsubscribe();
            }
            this.req = this.edisAssetsService
                .getAssets(
                    {
                        west: currentBounds.getWest(),
                        south: currentBounds.getSouth(),
                        north: currentBounds.getNorth(),
                        east: currentBounds.getEast()
                    },
                    this.voltageType
                )
                .subscribe(
                    (response: any[]) => {
                        const newLayerIndex = this.currentLayerIndex === 0 ? 1 : 0;
                        this.layer[newLayerIndex].clearLayers();

                        if (!this.cancelled) {
                            response.forEach((asset) => {
                                this.layer[newLayerIndex].addLayer(
                                    geoJSON(asset.geometry, {
                                        style: () =>
                                            MapLayerAssets.getLeafletStyle(asset.geometry.type, this.voltageType),
                                        pointToLayer: (feature, latlng) => {
                                            return circleMarker(latlng, circleSubstation);
                                        },
                                        onEachFeature: (feature, layer) => {
                                            layer.bindPopup(
                                                '<strong>ID:</strong> ' + asset.id.toString(),
                                                popupOptionsAssets
                                            );
                                        }
                                    })
                                );
                            });

                            this.layer[newLayerIndex].addTo(this.map);
                            this.layer[this.currentLayerIndex].clearLayers();
                            this.layer[this.currentLayerIndex].remove();
                            this.currentLayerIndex = newLayerIndex;
                        }

                        resolve();
                    },
                    (e) => {
                        console.log(e);
                        this.hide();
                        reject();
                    }
                );
        });
    }

    public hide(): void {
        this.cancelled = true;
        this.layer[0].remove();
        this.layer[1].remove();
    }
}
