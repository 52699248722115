const lineStringSearchResult = {
    color: '#27a737',
    weight: 4
};

const lineStringAssetLow = {
    color: '#00497a',
    weight: 2
};

const lineStringAssetMedium = {
    color: '#00497a',
    weight: 2,
    dashArray: '6,4'
};

export { lineStringSearchResult, lineStringAssetLow, lineStringAssetMedium };
