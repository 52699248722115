import { AppRoutingModule } from './app-routing.module';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CalendarModule } from 'primeng/calendar';
import { ClickOutsideModule } from 'ng-click-outside';
import { EdisAppComponent } from './app';
import { EdisAssetsService } from './services/assets/assets.service';
import { EdisButtonComponent } from './components/_common/button/button.component';
import { EdisCustomerService } from './services/customer/customer.service';
import { EdisDetailsCustomerComponent } from './components/details-customer/details-customer.component';
import { EdisDetailsIncidentComponent } from './components/details-incident/details-incident.component';
import { EdisDetailsOutageComponent } from './components/details-outage/details-outage.component';
import { EdisHeaderComponent } from './components/header/header.component';
import { EdisIconButtonComponent } from './components/_common/icon-button/icon-button.component';
import { EdisLayerSettingButtonComponent } from './components/_common/layer-setting-button/layer-setting-button.component';
import { EdisLiveDataService } from './services/live-data/live-data.service';
import { EdisLoginComponent } from './components/login/login.component';
import { EdisMainComponent } from './components/main/main.component';
import { EdisMapAddressSearchComponent } from './components/map-address-search/map-address-search.component';
import { EdisMapComponent } from './components/map/map.component';
import { EdisMapLayerSettingsComponent } from './components/map-layer-settings/map-layer-settings.component';
import { EdisMapService } from './services/map/map.service';
import { EdisNotificationComponent } from './components/_common/notification/notification.component';
import { EdisNotificationService } from './services/notification/notification.service';
import { EdisOverlayComponent } from './components/overlay/overlay.component';
import { EdisOverlayService } from './services/overlay/overlay.service';
import { EdisSwitchComponent } from './components/_common/switch/switch.component';
import { EdisTranslatePipe } from './pipes/translate/translate.pipe';
import { EdisTrapFocusDirective } from './directives/trap-focus/trap-focus.directive';
import { EdisUserDropdownComponent } from './components/user-dropdown/user-dropdown.component';
import { environment } from '../environments/environment';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import localeDe from '@angular/common/locales/de';
import localeDeExtra from '@angular/common/locales/extra/de';
import { LOCALE_ID } from '@angular/core';
import { NgModule } from '@angular/core';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { registerLocaleData } from '@angular/common';
import { ServiceWorkerModule } from '@angular/service-worker';
import { ToastModule } from 'primeng/toast';
import { YagaModule } from '@yaga/leaflet-ng2';
import { TooltipModule } from 'primeng/tooltip';

registerLocaleData(localeDe, 'de-DE', localeDeExtra);

@NgModule({
    declarations: [
        EdisAppComponent,
        EdisButtonComponent,
        EdisDetailsCustomerComponent,
        EdisDetailsIncidentComponent,
        EdisDetailsOutageComponent,
        EdisHeaderComponent,
        EdisIconButtonComponent,
        EdisLayerSettingButtonComponent,
        EdisMainComponent,
        EdisMapAddressSearchComponent,
        EdisMapComponent,
        EdisMapLayerSettingsComponent,
        EdisNotificationComponent,
        EdisOverlayComponent,
        EdisSwitchComponent,
        EdisTrapFocusDirective,
        EdisUserDropdownComponent,
        EdisLoginComponent,
        EdisTranslatePipe
    ],
    imports: [
        AppRoutingModule,
        BrowserModule,
        BrowserAnimationsModule,
        CalendarModule,
        ClickOutsideModule,
        FormsModule,
        HttpClientModule,
        ProgressSpinnerModule,
        ServiceWorkerModule.register('ngsw-worker.js', {
            enabled: environment.production
        }),
        ToastModule,
        YagaModule,
        TooltipModule
    ],
    providers: [
        { provide: LOCALE_ID, useValue: 'de-DE' },
        EdisAssetsService,
        EdisCustomerService,
        EdisNotificationService,
        EdisMapService,
        EdisLiveDataService,
        EdisOverlayService
    ],
    bootstrap: [EdisAppComponent]
})
export class AppModule {}
